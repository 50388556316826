<template>
  <div class="h-100 position-relative">
    <MainBodyHeader title="لیست عملیات">
      <div class="d-flex justify-content-end">
        <CButton
          color="primary"
          @click="showModal(0)"
        >
          ثبت عملیات جدید
        </CButton>
      </div>
    </MainBodyHeader>
    <div class="body-wrapper pt-0 overflow-hidden">
      <div>
        <div class="h-100">
          <vue-element-loading
            :active="getDetailLoading"
            spinner="bar-fade-scale"
            color="var(--secondary)"
          />
          <div class="mb-3">
            <date-picker
              color="var(--secondary)"
              v-model="dateViewDetail"
            ></date-picker>
          </div>
          <div v-if="operationDataDetail.length">
            <vue-element-loading
              :active="loading"
              spinner="bar-fade-scale"
              color="var(--secondary)"
            />
            <vue-element-loading
              :active="getDetailLoading"
              text="دریافت اطلاعات روز انتخاب شده ..."
              spinner="bar-fade-scale"
              color="var(--secondary)"
            />
           <div class="d-flex gap-10 flex-column overflow-y-auto" style="height: 49rem">
             <card-action
               class="animate__animated animate__slideInDown"
               :style="`animation-delay: 0.${index+2}s !important;`"
               v-for="(item, index) in operationDataDetail"
               :key="item.id"
               :title="item.farmName"
               @onCardHeaderAction="deleteOperation(item.id)"
             >
               <template #header-action-icon>
                 <font-awesome-icon icon="trash"/>
               </template>
               <CRow align-vertical="center">
                 <CCol md="auto">
                   <div class="item-wrapper">
                     <h6>{{ item.type }}</h6>
                   </div>
                 </CCol>
                 <CCol>
                   <div class="y-center-g-5">
                     <span>از</span>
                     <h6 class="chip">{{ item.perStartDateTime }}</h6>
                     <span>تا</span>
                     <h6 class="chip">{{ item.perEndDateTime }}</h6>
                   </div>
                 </CCol>
               </CRow>
               <hr class="my-2"/>
               <CRow class="gap-5">
                 <CCol class="text-center p-0">
                   <div class="y-center-g-5">
                     <CButton class="y-center-g-5" color="secondary" @click="showModal(item.id)">
                       <CIcon name="cilPencil"/>
                       <span>ویرایش</span>
                     </CButton>
                   </div>
                 </CCol>
               </CRow>
             </card-action>
           </div>
          </div>
          <div v-else-if="operationDataDetail.length === 0 && !getDetailLoading" class="flex-center flex-column h-100">
            <h6 class="emptyTitle">
              برای تاریخ {{ this.dateViewDetail }} عملیاتی ثبت نشده است.
            </h6>
            <img src="/img/no-data.png" class="w-50" alt="no result">
          </div>
        </div>
      </div>
      <div>
        <CCard class="mb-0">
          <CCardBody>
            <vue-element-loading
              :active="loading"
              spinner="bar-fade-scale"
              color="var(--secondary)"
            />
            <div>
              <vue-element-loading
                :active="deleteLoading"
                text="در حال حذف اطلاعات ..."
                spinner="bar-fade-scale"
                color="var(--secondary)"
              />
              <div :style="boxStyle">
                <full-calendar :options="calendarOptions"></full-calendar>
              </div>
            </div>
            <VueModal
              v-model="insertOperationModal"
              title="ثبت عملیات جدید"
              wrapper-class="animate__animated animate__faster"
              modal-class="drawer"
              in-class="animate__fadeInRight"
              out-class="animate__fadeOutRight"
              modelStyle="width:30vw !important;max-width:30vw !important">
              <vue-element-loading
                :active="loading"
                :text="loadingtext"
                spinner="bar-fade-scale"
                color="var(--secondary)"
              />
              <CForm>
                <CRow>
                  <CCol md="12">
                    <span class="ml-1">زمین</span>
                    <v-select
                      dir="rtl"
                      :isValid="checkIfValid('farmId')"
                      v-model="selectedfarmerFarm"
                      :options="farmerFarmSource"
                      placeholder="زمین خود را انتخاب کنید"
                    ></v-select>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol md="12">
                    <span class="ml-1">نوع عملیات</span>
                    <v-select
                      dir="rtl"
                      :isValid="checkIfValid('type')"
                      v-model="selectedType"
                      :options="TypeSource"
                      placeholder="نوع عملیات را انتخاب کنید"
                    ></v-select>
                  </CCol>
                </CRow>
                <br/>
                <br/>
                <CRow>
                  <CCol md="12">
                    <span class="ml-1">تاریخ شروع</span>
                    <date-picker
                      color="var(--secondary)"
                      v-model="form.startDateTime"
                      placeholder="تاریخ عملیات را انتخاب کنید"
                    ></date-picker>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <span class="ml-1">تاریخ پایان</span>
                    <date-picker
                      color="var(--secondary)"
                      v-model="form.endDateTime"
                      placeholder="تاریخ عملیات را انتخاب کنید"
                    ></date-picker>
                  </CCol>
                </CRow>
                <br/>
                <br/>
                <CRow>
                  <CCol md="12">
                    <CTextarea
                      :value.sync="$v.form.description.$model"
                      :isValid="checkIfValid('description')"
                      :lazy="false"
                      label="توضیحات"
                      rows="8"
                    />
                  </CCol>
                </CRow>
              </CForm>
              <CButton
                @click="submit()"
                color="primary"
                style="float: left; margin: 10px"
              >
                ثبت
                <CIcon name="cil-check"/>
              </CButton>
            </VueModal>
            <VueModal
              v-model="opertationFilter"
              title="جستجوی عملیات..."
              wrapper-class="animate__animated animate__faster"
            >
              <CRow>
                <CCol md="12 mt-4">
                  <multiselect
                    v-model="farms"
                    :options="farmsSource"
                    track-by="value"
                    label="text"
                    deselect-label=""
                    placeholder="نمایش تمام زمین ها"
                    selectLabel=""
                    @input="onChangeFarms"
                    :searchable="true"
                    :multiple="true"
                    :maxHeight="200"
                  ></multiselect>
                </CCol>
                <CCol md="12 mt-4">
                  <multiselect
                    v-model="types"
                    :options="typesSource"
                    track-by="value"
                    label="text"
                    deselect-label=""
                    placeholder="نمایش تمام نوع عملیات ها"
                    selectLabel=""
                    @input="onChangeTypes"
                    :searchable="true"
                    :multiple="true"
                    :maxHeight="200"
                  ></multiselect>
                </CCol>
                <CCol md="12 mt-4">
                  <date-picker
                    v-model="dateFilter"
                    color="var(--secondary)"
                    placeholder="نمایش تاریخ انتخابی"
                  ></date-picker>
                </CCol>
                <CCol md="12 mt-4">
                  <CButton
                    color="warning "
                    style="width: 7rem"
                    class="d-none d-md-block"
                    size="sm"
                    @click="operationFilter()"
                  >
                    جستجو
                  </CButton>
                </CCol>
              </CRow>
            </VueModal>
          </CCardBody>
        </CCard>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import useVuelidate from "@vuelidate/core";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Multiselect from "vue-multiselect";
import OperationType from "../../store/data/enum/operationType.json";
import * as moment from "jalali-moment";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import faLocale from "@fullcalendar/core/locales/fa";
import MainBodyHeader from "../../components/mainHeader.vue";
import CardAction from "../../components/cardAction.vue";

export default {
  setup() {
    return {v$: useVuelidate()};
  },
  name: "operationForm",
  components: {
    CardAction,
    MainBodyHeader,
    vSelect,
    Multiselect,
    FullCalendar,
  },
  data() {
    return {
      boxStyle: "",
      farmsSource: [],
      farms: [],
      typesSource: [],
      types: [],
      dateFilter: null,
      operationData: [],
      operationDataDetail: [],
      dateViewDetail: "",
      dateSelected: "",
      deleteLoading: false,
      getDetailLoading: false,
      loading: false,
      loadingtext: "در حال ارسال اطلاعات...",
      operationModalShow: false,
      heightSize: window.innerHeight - 200,
      form: this.getEmptyForm(),
      TypeSource: OperationType,
      selectedType: "",
      farmerFarmSource: [],
      selectedfarmerFarm: "",
      insertOperationModal: false,
      opertationFilter: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        height: window.innerHeight - 260,
        locale: faLocale,
        timeZone: "local",
        direction: "rtl",
        allDaySlot: true,
        weekends: true,
        monthends: true,
        dateClick: this.handleDateClick,
        events: [],
      },
    };
  },
  mixins: [validationMixin],
  computed: {
    isValid() {
      return !this.$v.form.$invalid;
    },
  },
  validations: {
    form: {
      farmId: {
        required,
      },
      type: {
        required,
      },
      description: {},
    },
  },
  watch: {
    dateFilter: function () {
      if (this.dateFilter) {
        this.fetchData();
      }
    },
    insertOperationModal: function () {
      if (this.insertOperationModal == false) {
        this.dateSelected = "";
      }
    },
    dateViewDetail: function () {
      this.currnetDateDetail();
    },
  },
  methods: {
    ...mapActions("farmOperation", [
      "GetAll",
      "GetWithMonth",
      "GetWithDay",
      "GetById",
      "Insert",
      "Edit",
      "Delete",
    ]),
    ...mapActions("farm", ["GetsTextValue"]),

    //Methods
    handleDateClick(arg) {
      this.dateSelected = moment(arg.dateStr).locale("fa").format("YYYY/MM/D");
      this.showModal(0);
    },
    async onChangeFarms(value) {
      this.farms = value;
      if (value.indexOf("Reset me!") !== -1) this.farms = [];
      await this.fetchData();
    },
    async onChangeTypes(value) {
      this.types = value;
      if (value.indexOf("Reset me!") !== -1) this.types = [];
      await this.fetchData();
    },
    async getFarmsList() {
      var result = await this.GetsTextValue({});
      result.filter((item) => {
        let farm = {
          text: item.text,
          value: item.value,
        };
        this.farmsSource.push(farm);
      });
    },
    getEmptyForm() {
      return {
        id: 0,
        farmId: "",
        description: "",
        startDateTime: this.dateSelected,
        endDateTime: "",
        Type: "",
      };
    },
    async getFarmerFarm() {
      this.farmerFarmSource = [];
      var result = await this.GetsTextValue({});
      result.filter((item) => {
        let farm = {
          id: item.value,
          label: item.text,
        };
        this.farmerFarmSource.push(farm);
      });
    },
    getTypesSource() {
      OperationType.filter((item) => {
        if (item.label) {
          let type = {
            text: item.label,
            value: item.value,
          };
          this.typesSource.push(type);
        }
      });
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },

    //Action
    async fetchData() {
      let currentDate = moment().locale("en").format("YYYY-MM-DD");
      let result = await this.GetAll({
        pageNumber: 0,
        pageSize: 0,
      });
      if (result) {
        this.operationData = result.map((item) => {
          return {
            title: item.title,
            start: item.geStartDate,
            end: item.geEndDate,
            color: moment(item.geStartDate).isBefore(currentDate)
              ? "#008d2f"
              : moment(item.geStartDate).isSame(currentDate)
                ? "#0276d3"
                : moment(item.geStartDate).isAfter(currentDate)
                  ? "#F80"
                  : "#bbb",
          };
        });
        this.calendarOptions.events = this.operationData;
      } else {
        this.$notify({
          title: "خطا رخ داده است",
          text: "امکان دریافت لیست نیست <br /><br />",
          type: "error",
        });
      }
    },
    async currnetDateDetail() {
      this.getDetailLoading = true;
      let result = await this.GetWithDay({
        currentDay: this.dateViewDetail,
      });
      this.getDetailLoading = false;
      if (result) {
        this.operationDataDetail = result;
      } else {
        if (this.dateViewDetail == null || this.dateViewDetail == "")
          this.dateViewDetail = moment().locale("fa").format("YYYY/MM/DD");
        this.$notify({
          title: "پیام سیستم",
          text: `در تاریخ ${this.dateViewDetail} هیج عملیاتی ثبت نشده است`,
          type: "info",
        });
      }
    },
    async submit() {
      this.form.type = this.selectedType.value;
      this.form.farmId = this.selectedfarmerFarm.id;
      this.v$.$validate();
      if (this.v$.$error) {
        this.$notify({
          title: "پیام سیستم",
          text: "اطلاعات وارد شده معتبر نیستند <br /><br />",
          type: "error",
        });
        return;
      }
      this.loading = true;
      const {form} = this;
      var result =
        form.id == 0
          ? await this.Insert({operation: form})
          : await this.Edit({operation: form});
      this.loading = false;
      if (result) {
        this.$notify({
          title: "پیام سیستم",
          text:
            form.id == 0
              ? "ثبت عملیات انجام شد   <br /><br />"
              : "اطلاعات عملیات ویرایش شد  <br /><br />",
          type: "success",
        });
        await this.fetchData();
        this.operationModalShow = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا رخ داده است <br /><br />",
          type: "error",
        });
        this.operationModalShow = false;
      }
      this.insertOperationModal = false;
    },
    async showModal(id) {
      this.insertOperationModal = true;
      await this.getFarmerFarm();
      if (id == 0) {
        this.form = this.getEmptyForm();
        this.operationModalShow = true;
      } else {
        let result = await this.GetById({id: id});
        if (result) {
          this.form = result;
          this.selectedType = this.TypeSource.filter((item) => {
            return item.value == this.form.type;
          })[0];
          this.selectedfarmerFarm = this.farmerFarmSource.filter((item) => {
            return item.id == this.form.farmId;
          })[0];
          this.operationModalShow = true;
        }
      }
    },

    //Filter
    async deleteFilter() {
      this.dateFilter = null;
      this.farms = [];
      this.types = [];
      await this.fetchData();
    },
    async deleteOperation(id) {
      this.$confirm({
        message: `عملیات مورد نظر حذف شود؟`,
        button: {
          no: "خیر",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            let result = await this.Delete({id: id});
            if (result) {
              await this.fetchData();
              await this.currnetDateDetail();
              this.$notify({
                title: "پیام سیستم",
                text: "سطر مورد نظر حذف شد <br /><br />",
                type: "success",
              });
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: "امکان حذف این سطر وجود ندارد <br /><br />",
                type: "error",
              });
            }
          }
        },
      });
    },
    operationFilter() {
      this.opertationFilter = true;
    },
    matchHeight() {
      this.boxStyle = `height: ${this.heightSize}px`;
    },
  },

  created() {
    window.deleteOperation = this.deleteOperation;
    window.showModal = this.showModal;
  },
  mounted() {
    this.matchHeight();
    this.fetchData();
    this.dateViewDetail = moment().locale("fa").format("YYYY/MM/DD");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.body-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
}

.vueconf {
  background-color: #00a65a !important;
}

.clearFilterBtn {
  padding: 2px 0 2px 0;
  height: 30px;
  width: 80px;
  background-color: #f00;
  color: #fff;
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

.fc .fc-daygrid-day-frame:hover {
  background-color: rgb(252, 172, 81);
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(193, 255, 193) !important;
} 
</style>
<style>
div.form-control {
  margin-left: 1rem;
}
</style>
